// ********************************************
// list view
// modul-apotheker-grid-overview
// ********************************************


// mixin

.bp-readme {
    //   Breakpoint mixin by Christian Brandt
    //     Usage :
    //
    //     @include bp($value, $minmax, $property, $orientation) {
    //        CSS
    //      }
    //
    //      Parameter:
    //
    //      $value: value of breakpoint
    //              keyword or value with unit (see table below eg: small, medium, 1337px, 666rem  etc.)
    //              optional
    //              default: null
    //
    //      $minmax: type (min or max)
    //               min or max
    //               optional
    //               default: min
    //
    //      $property: width or height
    //                 width, height, for the lazy w or h
    //                 optional
    //                 default: width
    //
    //      $orientation: portrait or landscape
    //                    portrait, landscape, for the lazy po or ls
    //                    optional
    //                    default: null
    //
    //      Examples:
    //
    //      .foo {
    //        @include bp(small) {
    //            margin: 0;
    //        }
    //      }
    //
    //      .bar {
    //        @include bp(small,max,h,ls) {
    //            margin: 10px;
    //        }
    //      }
    //
    //      .baz {
    //        @include bp(100px,max,$orientation:ls){
    //            margin: 200px;
    //        }
    //      }
    //
    //
    //
    //      CSS:
    //      @media only screen and (min-width: 640px) {
    //        .foo {
    //            margin:0;
    //        }
    //      }
    //
    //     @media only screen and (max-height: 640px) and (orientation: landscape) {
    //        .bar {
    //            margin:0;
    //        }
    //      }
    //
    //      @media only screen and (max-width: 100px) and (orientation: landscape) {
    //        .baz {
    //            margin: 200px;
    //        }
    //      }
    //
}

@mixin respond($value: null, $minmax: null, $property: null, $orientation: null) {
    $query: ' only screen';
    $query-minmax: null;
    $query-property: null;
    $query-dimension: null;
    $query-orientation: null;
    @if ($value) {
        @if ($minmax) {
            @if($minmax == 'min') or ($minmax == 'max') {
                $query-minmax: $minmax;
            } @else {
                $query-minmax: 'min';
            }
        } @else {
            $query-minmax: 'min';
        }
        @if($property) {
            @if($property == 'width') or ($property == 'w') {
                $query-property: 'width';
            }
            @if ($property == 'height') or ($property == 'h') {
                $query-property: 'height';
            }
        } @else {
            $query-property: 'width';
        }
        // scss-lint:disable SpaceAroundOperator
        $query-dimension: 'and (' + $query-minmax +'-'+ $query-property +': '+ $value +')';
    } @else {
        $query-dimension: '';
    }
    @if ($orientation) {
        @if($orientation == 'portrait') or ($orientation == 'po') or ($orientation == 'p') {
            $query-orientation: 'and (orientation: portrait)';
        }

        @if($orientation == 'landscape') or ($orientation == 'ls') or ($orientation == 'l') {
            $query-orientation: 'and (orientation: landscape)';
        }
    } @else {
        $query-orientation: '';
    }

    @media #{$query + ' ' + $query-dimension+$query-orientation}  {
        @content
    }
}

$break-xxxsmall: 321px;
$break-xxsmall: 480px;
$break-xsmall: 568px;
$break-small: 640px;
$break-smallmedium: 680px;
$break-medium: 768px;
$break-mediumlarge: 992px;
$break-max-mobile-ls: 1023px;
$break-large: 1024px;
$break-xlarge: 1170px;
$break-xxlarge: 1280px;
$break-xxxlarge: 1720px;


@mixin bp($media: null, $minmax: null, $property: null, $orientation: null) {
    @if $media == xxxsmall {
        @include respond($break-xxxsmall, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == xxsmall {
        @include respond($break-xxsmall, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == xsmall {
        @include respond($break-xsmall, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == small {
        @include respond($break-small, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == smallmedium {
        @include respond($break-smallmedium, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == medium {
        @include respond($break-medium, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == mediumlarge {
        @include respond($break-mediumlarge, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == max-mobile-ls {
        @include respond($break-max-mobile-ls, max, $property, $orientation) {
            @content
        }
    } @else if $media == large {
        @include respond($break-large, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == xlarge {
        @include respond($break-xlarge, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == xxlarge {
        @include respond($break-xxlarge, $minmax, $property, $orientation) {
            @content
        }
    } @else if $media == po {
        @include respond(null, $minmax, $property, $orientation: (po)) {
            @content
        }
    } @else if $media == ls {
        @include respond(null, $minmax, $property, $orientation: (ls)) {
            @content
        }
    } @else if $media == xxxlarge {
        @include respond($break-xxxlarge, $minmax, $property, $orientation) {
            @content
        }
    } @else {
        @include respond($media, $minmax, $property, $orientation) {
            @content
        }
    }
}




.modul-apotheker-grid-overview {
    margin-bottom: 7.5rem;

    .modul-apotheker-grid-overview__box {
        position: relative;
        height: 215px;
        margin-bottom: 30px;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;

        img {
            width: 45%;
            height: 100%;
            display: inline-block;
            background: #3f3f3f;
            float: left;
            vertical-align: top;
        }

        .modul-apotheker-grid-overview__right {
            display: table-cell !important;
            width: 55%;
            height: 100%;
            padding: 15px;
            line-height: 1.4;

            .modul-apotheker-grid-overview__quote {
                // height: 88px;
                overflow: hidden;
                color: #194b7d;

                -webkit-line-clamp: 3;
                line-clamp: 3;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                height: 57px !important;
                margin-bottom: 15px;

                @include bp(767px, max) {
                    height: 50px !important;
                }
            }

            a.btn {
                padding: 0;
                position: absolute;
                right: 0;
                bottom: 0;
                margin-bottom: 15px;
                margin-right: 15px;
                color: #bb1883;
                text-align: right;
            }
        }

        span.modul-apotheker-grid-overview__distance {
            color: #777777;
            font-size: 13px;

            position: absolute;
            bottom: 15px;
            left: auto;
        }
        span.modul-apotheker-grid-overview__distance i {
            opacity: 0.7
        }
    }

    .modul-apotheker-grid-overview__resultnote {
        color: #555555;
        padding-bottom: 35px;
    }

    #btn-show-more {
        position: relative;
        color: #000000;
        font-size: 14px;
        opacity: 0.25;
        margin-top: 2.5rem;

        &:hover,
        &:focus {
            color: #000000;
        }

        &:before {
            content: " ";
            position: absolute;
            top: -5px;
            left: 50%;

            width: 40px;
            height: 12px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/fileadmin/user_upload/SVG/more.svg");

            transform: translateX(-50%);
        }
    }
}

// ********************************************
// list view, search
// modul-apotheker-search
// ********************************************

.modul-apotheker-search {
    margin-bottom: 7.5rem;

    .locationsearch_container {
        position: relative;
        width: 350px;
        float: left;
        margin: 0 10px 0 0;

        &:before {
            content: ' ' !important;
            opacity: .5 !important;
            top: 12px;
            left: 5px;
            height: 25px;
            width: 25px;
            background: url(/fileadmin/user_upload/SVG/map-marker.svg);
            position: absolute;
        }
    }

    input#locationsearch {
        padding-left: 35px;
        height: 47px;
        line-height: 47px;
        background: #eeeeee;
    }

    .form_productsearch {
        float: right;
        width: 100%;

        &:before {
            content: "oder";
            position: absolute;
            top: 10px;
            left: -17px;
            opacity: .75;
        }

        .productsearch_container {
            position: relative;
            width: 350px;
            margin: 0 10px 0 0;
            float: right;

            &:before {
                content: ' ' !important;
                opacity: .5 !important;
                top: 12px;
                left: 12px;
                height: 25px;
                width: 25px;
                background: url("/fileadmin/user_upload/SVG/pt_product-grid.svg");
                position: absolute;
            }

            .provproduct {
                text-indent: 38px;
            }

        }

        select#productsearch {
            padding: 0 0 0 10px;
            height: 47px;
            line-height: 47px;
            background: #eeeeee;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #cccccc;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            appearance: none;
            -webkit-appearance: none;
        }

        .btn {
            margin-right: 0;
            float: right;
        }
    }

}

// ********************************************
// detail view, search
// modul-apotheker-details
// ********************************************

.modul-apotheker-details {

    .relative {
        position: relative;
    }

    .page-teaser {

        .gradient-white-horizontal {
            z-index: 1;
        }

        .image-mask {
            z-index: 1;
            position: relative;
            top: -50px;
            left: 50%;
            display: block;
            width: 625px;
            height: 625px;
            border-radius: 100%;
            overflow: hidden;
            background: #cccccc;
            -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        }

        .header_text {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            h1 {
                position: relative;
                z-index: 2;
                top: 70px;
                font-size: 64px;
                font-weight: bold;
                line-height: 1.1;
                color: #194b7d;
            }
            h2 {
                position: relative;
                z-index: 2;
                top: 60px;
                font-size: 32px;
                font-weight: bold;
                line-height: 1.1;
                color: #111111;
            }
            .context {
                position: relative;
                z-index: 2;
                top: 100px;
                font-size: 18px;
                font-weight: normal;
                line-height: 1.3;
                color: #111111;
            }
        }
    }

    .teaser {
        h2 {
            text-align: center;
            color: #194b7d;
            font-size: 32px;
        }
        .quote {
            text-align: center;
            font-size: 42px;
            font-weight: normal;
            line-height: 1;
        }
        .quote_author {
            text-align: center;
        }
    }

}

.modul-apotheker-kontakt {
    .background-color-1 {
        background: #f6f6f6;
        padding-top: 50px;
    }

    div#c13375 {
        padding-top: 53px;
    }

    .powermail_fieldwrap.powermail_fieldwrap_type_html.powermail_fieldwrap_introtext div {
        margin: 0 !important;
        width: 100% !important;
    }

}

.modul-apotheker-factsheet {
    padding: 60px 0 80px 0;
    margin: auto;
    text-align: center;

    h3 {
        font-size: 32px;
        color: #194b7d;
    }
    p {
        padding: 20px 0;
    }
}

// ********************************************
// detail view, search
// modul-fixed-factsheet
// ********************************************


.modul-fixed-factsheet {
    position: fixed;
    left: 0;
    right: 0;
    top: 160px;
    background: #bb1883;
    color: #ffffff;
    z-index: 100;
    opacity: 0;
    -webkit-animation: fade-in 300ms ease 5s forwards;
    animation: fade-in 300ms ease 5s forwards;
    strong {
        color: #ffffff !important;
    }
    .btn.btn-primary {
        color: #fff;
        background: #bb1883;
        border: 2px solid #fff;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet {
        top: 55px;
    }
}

.modul-fixed-factsheet.affix {
    top: 65px;
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet.affix {
        top: 55px;
    }
}

.modul-fixed-factsheet [class^="col"] {
    min-height: 100px;
}

.modul-fixed-factsheet .vertical-center {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.modul-fixed-factsheet p {
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet p {
        font-size: 14px;
    }
}

.modul-fixed-factsheet .modul-fixed-factsheet__name {
    font-weight: bolder !important;
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet .modul-fixed-factsheet__name {
        font-weight: initial !important;
    }
}

.modul-fixed-factsheet .modul-fixed-factsheet__mob-name {
    display: none;
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet .modul-fixed-factsheet__mob-name {
        font-weight: lighter !important;
        display: inline-block;
    }
}

.modul-fixed-factsheet .modul-fixed-factsheet__right {
    right: 15px;
}

.modul-fixed-factsheet .btn-inverted {
    background: white;
    color: #000000;
    margin: 0 2rem 0 0;
}

@media only screen and (max-width: 768px) {
    .modul-fixed-factsheet .btn-inverted {
        padding: 10px 10px !important;
        width: auto;
    }
}

.modul-fixed-factsheet .fa.fa-close {
    cursor: pointer;
    font-size: 26px;
    position: relative;
    top: 4px;
}


// ********************************************
// circle of competence
// modul-berater-finden
// ********************************************

.modul-berater-finden {

    form.form_plzsearch {
        margin: auto;
        text-align: center;
        width: 100%;
        max-width: 430px;

        .plzsearch_container {
            position: relative;
            width: 250px;
            margin: 0 10px 0 0;
            float: left;

            &:before {
                content: ' ' !important;
                opacity: .5 !important;
                top: 12px;
                left: 5px;
                height: 25px;
                width: 25px;
                background: url(/fileadmin/user_upload/SVG/map-marker.svg);
                position: absolute;
            }
        }

        input#plzsearch {
            padding-left: 35px;
            height: 47px;
            line-height: 47px;
            background: #eeeeee;
        }

        .btn {
            margin-right: 0;
        }
    }

    .dce-digitaleapotheke.dce-circle-of-competence {
        padding: 60px 0;
        text-align: center;

        .logo-wrapper {
            position: relative;
        }

        .logo {
            .context {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                z-index: 7;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
                cursor: pointer;
                opacity: 0;
                background: rgba(255, 255, 255, 0);
                transition: all 0.5s;

                &.active {
                    opacity: 1;
                    background: rgba(255, 255, 255, 0.70);
                    transition: all 0.5s;
                }

                h3 {
                    font-weight: 700;
                    font-size: 16px;
                    color: #194b7d;
                    padding: 30px 0 0 0;
                }
                p.position, p.contact {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    color: #333333;
                }
                p.contact {
                    padding-top: 10px;
                    font-size: 14px;

                    a {
                        color: #333333;
                    }
                }
            }
            .image {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }

    a.mbemail {
        text-decoration: underline;
    }
}

.modul-berater-contact {
    div#jq-gm-view-berater-map {
        width: 100%;
        height: 400px;
    }

    .modul-berater-contact__map {
        position: relative;
        height: 400px;
        width: 100%;
        background: #efefef;
    }

    .modul-berater-contact__map:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: inset 0 0 100px 60px rgba(246, 246, 246, 1);
        bottom: 0;
        z-index: 99;
    }

}

